import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from "react-helmet";

export const query = graphql`
    query RedirectTemplateQuery($id: String!) {
        model: sanityRedirect(id: { eq: $id }) {
            title
            description
            slug {
                current
            }
            redirectUrl
        }
    }
`

const RedirectTemplate = (props) => {

    const { title, description, redirectUrl } = props.data.model

    const httpEquiv = "0; url = " + redirectUrl

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta http-equiv="refresh" content={httpEquiv} />
        </Helmet>
    )
    
    return (
        <p>{redirectUrl}</p>
    )

}

export default RedirectTemplate
